<template>
  <v-card id="pricing-plan"
          class="text-center py-sm-15 py-5">
    <v-card-text>
      <v-row>
        <v-col cols="12"
               md="6"
               sm="8"
               class="mx-auto">
          <h1 class="font-weight-medium mb-5">
            Downloads
          </h1>
          <p class="mb-3">
            Download the latest versions of our software
          </p>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text class="mt-3 pb-15">
      <v-row>
        <v-col offset-sm="2"
               sm="8"
               md="12"
               offset-lg="200"
               lg="7"
               class="mx-auto">
          <v-row>
            <!-- Composer -->
            <v-col cols="12"
                   md="4">
              <v-card outlined
                      class="text-center">
                <v-card-text>
                  <!-- image -->
                  <div class="d-flex align-center justify-center">
                    <v-img :src="images[0]"
                           class="rounded-image"
                           contain />
                  </div>

                  <!-- title -->
                  <h1 class="text-2xl font-weight-medium">
                    Composer
                  </h1>
                  <p>Upload and edit your models</p>
                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price d-flex align-center justify-center">
                      <v-btn block
                             color="primary"
                             type="submit"
                             class="mt-6"
                             @click.prevent="GetComposer">
                          Download
                      </v-btn>
                    </div>
                  </div>
                  <!--/ annual plan -->
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Desktop Viewer -->
            <v-col cols="12"
                   md="4">
              <v-card outlined
                      class="text-center">
                <v-card-text>
                  <!-- image -->
                  <div class="d-flex align-center justify-center">
                    <v-img :src="images[1]"
                           class="rounded-image"
                           contain/>
                  </div>

                  <!-- title -->
                  <h1 class="text-2xl font-weight-medium">
                    Desktop
                  </h1>
                  <p> Viewer </p>
                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price d-flex align-center justify-center">
                      <v-btn block
                             color="primary"
                             type="submit"
                             class="mt-6"
                             @click.prevent="GetDesktop">>

                        Download
                      </v-btn>
                    </div>
                  </div>
                  <!--/ annual plan -->
                </v-card-text>
              </v-card>
            </v-col>
            <!-- Cave Viewer -->
            <v-col cols="12"
                   md="4">
              <v-card outlined
                      class="text-center">
                <v-card-text>
                  <!-- image -->
                  <div class="d-flex align-center justify-center">

                    <v-img :src="images[2]"
                           class="rounded-image"
                           contain />
                  </div>
                  <!-- title -->
                  <h1 class="text-2xl font-weight-medium">
                    Studio
                  </h1>
                  <p> Group viewer </p>
                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price d-flex align-center justify-center">
                      <v-btn block
                             color="primary"
                             type="submit"
                             class="mt-6"
                             @click.prevent="GetStudio">>
                        Download
                      </v-btn>
                    </div>
                  </div>
                  <!--/ annual plan -->
                </v-card-text>
              </v-card>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { storage } from '@/Firebase/Firebase.service'
  import { ref as storageRef, getDownloadURL } from "firebase/storage";
  export default {
    title: 'Epiito - downloads',

    methods: {
      GetComposer() {
        var ref = storageRef(storage, 'gs://epiito.appspot.com/Software/Editor/EpiitoEditorSetup.msi')
        getDownloadURL(ref).then((downloadLink) => {
          window.open(downloadLink, '_parent', 'download');
        })
      },
      GetDesktop() {
        var ref = storageRef(storage, 'gs://epiito.appspot.com/Software/Desktop/EpiitoDesktopSetup.msi')
        getDownloadURL(ref).then((downloadLink) => {
          window.open(downloadLink, '_parent', 'download');
        })
      },
      GetStudio() {
        var ref = storageRef(storage, 'gs://epiito.appspot.com/Software/Cave/EpiitoCaveSetup.msi')
        getDownloadURL(ref).then((downloadLink) => {
          window.open(downloadLink, '_parent', 'download');
        })
      },
    },

    data: function () {
      return {
        images: [
          require('@/assets/epiito/appIcons/Epiito_App_Icons_Editor.png'),
          require('@/assets/epiito/appIcons/Epiito_App_Icons_Desktop.png'),
          require('@/assets/epiito/appIcons/Epiito_App_Icons_CAVE.png'),
        ],
      }
    }
  }
</script>
<style lang="scss">
  @import '@core/preset/preset/apps/user.scss';

  .rounded-image {
    border-radius: 4%;
    min-height: 200px;
    min-width: 200px;
  }

</style>
